const timeNavigatorData = [
  {
    label: "Day",
    id: 1,
    disabled: false,
  },
  {
    label: "Week",
    id: 2,
    disabled: false,
  },
  {
    label: "Month",
    id: 3,
    disabled: false,
  },
  {
    label: "Year",
    id: 4,
    disabled: false,
  },
  {
    label: "Years",
    id: 5,
    disabled: false,
  },
];

export default timeNavigatorData;
